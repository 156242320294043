// ================  公共 start ======================================================================

/**
 * 根据key获取value
 * @param optionArr
 * @param key
 */
export function getValue(optionArr, key) {
  let resultVal = "";
  if (!optionArr || optionArr.length === 0) {
    return resultVal;
  }
  optionArr.map(item => {
    if (item.key === key) {
      resultVal = item.value;
    }
  });
  return resultVal;
}

/**
 * 设备的状态 1-装配中，2-空闲中，3-预约中, 4-作业中，5-待维护，6-维修中
 * @type {[{}]}
 */
export const INTLLIGENT_STATUS_CSS = [
  {
    key: 1,
    value: "#000000"
  },
  {
    key: 2,
    value: "#8BC34A"
  },
  {
    key: 3,
    value: "#1682e6"
  },
  {
    key: 4,
    value: "#11b95c"
  },
  {
    key: 5,
    value: "#e6a700"
  },
  {
    key: 6,
    value: "red"
  }
];
